<template>
  <div class="home">
    <main>
      <h1>Altersabteilung der Freiwillige Feuerwehr Lunestedt</h1>
    <article class="altmain">
      
      <h2>Allgemeine Infos zur Altersabteilung</h2>
      <p>In der Altersabteilung der Freiwilligen Feuerwehr Lunestedt finden sich mehr als 20 
        verdiente Kameraden monatlich zu einem Kameradschaftsabend zusammen, um über vergangene Zeiten zu 
        sprechen und zu lachen. 
        Geleitet wird die Altersabteilung vom Gruppenleiter und seiner Stellvertreter.
      <br></p>
      <div><button @click="isVisible = !isVisible">Bilder</button>
      <div class="bild_parent" v-if="isVisible">
        <div v-for="item in allgemein" :key="item" class="bilder">
          <img :src="require(`@/components/images/alters/allgemeines/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
      
      <br>
      <h2>Was macht die Altersabteilung</h2>
      <p>Sie unterstützt die Einsatzabteilung tatkräftig bei vielen Aufgaben. 
        Sei es bei der erforderlichen, zweimal jährlichen Hydrantenpflege, Liegenschaftsarbeiten, 
        Reinigungsdiensten oder bei Veranstaltungen: Die Altersabteilung gehört für uns immer dazu!
        Aber auch Grillabende oder Ausflüge sind auf der Tagesordnung zu finden. 
        Zudem rundet eine gemütliche Weihnachtsfeier das Jahr ab.
        Darüber hinaus finden alle zwei Jahre die Alterswettbewerbe der Gemeinde Beverstedt statt, 
        an denen unsere Kameraden aus der Altersabteilung teilnehmen.
      <br>
      In Corona-Zeiten wurde durch die ausfallenden Präsenzabende der Einfallsreichtum 
      der Altersabteilung groß und man entschloss sich, unsere alte Spritze von 1902 zu restaurieren. 
      Hierzu wurden verschiedene Gewerke eingerichtet, sodass fast jeder eine Aufgabe hatte. 
      Das Ergebnis nach mehr als 500 Arbeitsstunden sieht nicht nur beeindruckend aus, 
      sondern ist auch funktionsfähig.
      <br></p>
      <div><button @click="isVisible1 = !isVisible1">Bilder</button>
      <div class="bild_parent" v-if="isVisible1">
        <div v-for="item in was" :key="item" class="bilder">
          <img :src="require(`@/components/images/alters/was/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div>

      <br>
    
      <br>
      <h3>Kontakt</h3>
      <p>Uwe Böschen, Gruppenleiter</p>
      <P>E-Mail: &#97;&#108;&#116;&#101;&#114;&#115;&#97;&#98;&#116;&#101;&#105;&#108;&#117;&#110;&#103;&#64;&#102;&#101;&#117;&#101;&#114;&#119;&#101;&#104;&#114;&#45;&#108;&#117;&#110;&#101;&#115;&#116;&#101;&#100;&#116;&#46;&#100;&#101;</P>
    </article>
</main>
  </div>
</template>

<script>

export default {
  name: 'AltersAbteilung',
  data() {
    return {
      isVisible: false,
      isVisible1: false,
      isVisible2: false,
      isVisible3: false,
      //Hier Bilder allgemein zur JF (Lunestedt) einfügen
      allgemein: [
        {url: 'gruppenalt.jpg', name: 'Gruppenbild der Altersabteilung Lunestedt'},
      ],
      //Hier Bilder zum Übungsdienst und was die JF macht einfügen
      was: [
        {url: 'wettbewerb.jpg', name: 'Einladung Seniorenwettbewerbe'},
        {url: 'urkunde.jpg', name: 'Urkunde der Altersabteilung Lunestedt'},
        {url: 'alt4.jpg', name: 'Bild der Spritze aus verschiedenen Winkeln'},
        {url: 'alt5.jpg', name: 'Bild der Spritze aus verschiedenen Winkeln'},
        {url: 'alt6.jpg', name: 'Bild der Spritze aus verschiedenen Winkeln'},
        {url: 'alt7.jpg', name: 'Bild der Spritze aus verschiedenen Winkeln'},
        {url: 'alt8.jpg', name: 'Bild der Spritze aus verschiedenen Winkeln'},
        {url: 'alt9.jpg', name: 'Bild der Spritze aus verschiedenen Winkeln'},
        {url: 'altheute.jpg', name: 'Vergleich Spritze 1902 und Fahrzeug heute'},
      ],
    }
  },
  components: {

  }
}
</script>
<style scoped>
.altmain {
  margin-top: 20px;
}
.bild_parent {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: solid 1px;
  border-radius: 5px;
  border-color: var(--inverted-color);
  padding-bottom: 15px;
  margin-top: 10px;
}
.bilder {
  width: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 15px;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  border-radius: 5px;
  border-color: var(--inverted-color);
  background-color: transparent;
  font-size: 20px;
  color: var(--inverted-color);
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: 0.3s;
}
button:hover {
  color: var(--complementary-color);
  transition: 0.3s;
}
h2 {
  color: var(--primary-color);
  margin-top: 50px;
}
h3 {
  margin-top: 50px;
}
</style>